/* --------------------------------------------------
	6.0 FAQ - Accordions
-------------------------------------------------- */



.accordions-1 {

	.panel {
		border-radius: 0;
		border: none;
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);

		.panel-heading {
			background: $lighter;
			padding: 25px 50px;
			border: none;
			border-radius: 0;

			.panel-title {
				@extend h6;

				a {

					&:focus {
						text-decoration: none;
					}
				}
			}
		} // .panel-heading

		.panel-collapse {

			.panel-body {
				border: none;
				border-top: 1px solid #eaeaea;
				background: $lighter;
				padding: 30px 50px;
			} // .panel-body
		} // .panel-collapse
	} // .panel

	.panel+.panel {
		margin-top: 20px;
	}
} // .accordions-1

.faq-contact-links {
	color: $gray-light;
	font-size: .9em;

	i {
		color: $gray-light;
	}

	a {
		color: $dark;
		padding-bottom: 4px;
		border-bottom: 1px solid rgba(17,17,17, 0);
		transition: all .3s;


		&:hover {
			color: $dark;
			padding-bottom: 1px;
			border-bottom: 1px solid rgba(17,17,17, 1);
		}
	}
}