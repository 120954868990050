/* --------------------------------------------------
	X Line Icons - Page
-------------------------------------------------- */



.line-icons {

	.how-to-use {
		padding-top: 15px;
		padding-bottom: 15px;
		border: 1px solid #ccc;
		background: $light-bg;

		p {
			margin-top: 15px;
			font-weight: 400;
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			display: block;
			margin-bottom: 40px;
			padding: 35px 15px;
			border: 1px solid #ccc;
			text-align: center;
			transition: background .3s;

			&:hover {
				background: $light-bg;

				span {
					color: $dark;
				}
			}

			span {
				display: block;
				font-size: 50px;
				color: $gray;
				transition: color .5s;
			}

			p {
				margin-top: 25px;
				font-size: 1.2em;
				color: $gray-light;
			}
		}
	}
}