/* --------------------------------------------------
	8.0 Navigation
-------------------------------------------------- */



.navbar {
	margin-bottom: 0;
	min-height: $navbar-higth;
	border-bottom: 1px solid $navbar-border-color;
	border-radius: 0;
	background: $navbar-background;
	
	.navbar-header {
		width: 27%;
		margin-top: 6px;

		.navbar-brand {
			padding: 15px 0;
			margin-left: 0;
		}
	}

	.navbar-nav {
		margin-top: 12px;
		
		li {

			a {
				text-transform: uppercase;
				font-size: $nav-links-font-size;
				font-weight: $nav-links-font-weight;
				letter-spacing: $nav-links-letter-spacing;
				color: $nav-links-color;
				transition: color .5s;

				&:focus {
					color: inherit;
				}

				&:hover {
					background: none;
					color: $nav-links-color-hover;
				}
				
				// for bag icon in the nav.
				i[class*="linea-"] {
					font-size: 18px;
					vertical-align: sub;
				}

				> .cart-notif {
					display: inline-block;
					position: relative;
					padding-left: 1px;
					width: 16px;
					height: 16px;
					top: -8px;
					right: -8px;
					border-radius: 100px;
					background: #EF413F;
					color: $white;
					font-size: 10px;
					line-height: 16px;
					text-align: center;
				}
			}
		}

		.active {

			a {
				background: none;
				color: $nav-link-active-color;
				transition: color .5s;

				&:hover,
				&:focus,
				&:visited {
					background: none;
				}
			}
		}

		.dropdown {

			.dropdown-toggle {
				background: none;

				&:hover,
				&:focus,
				&:visited {
					background: none;
				}

				.fa-shopping-cart {
					margin-left: 16px;
				}
			}

			.dropdown-menu {
				margin-top: 12px;
				padding: $dropdown-padding;
				border: 1px solid $light;
				border-radius: 0;
				box-shadow: none;
				background: $dropdown-background;

				li {
					min-width: 150px;

					a {
						display: block;
						padding: $dropdown-link-padding;
						letter-spacing: $dropdown-link-letter-spacing;
						line-height: $dropdown-link-line-height;
						border-left: 3px solid rgba(17,17,17, 0);
						transition: all .3s;
						color: $dropdown-links-color;

						&:hover {
							padding-left: $dropdown-link-shift;
							padding-right: 30px; // -10px so it won`t shrink;
							background: $dropdown-link-background;
							border-left: $dropdown-link-left-border;
						}

						i {
							padding-right: 5px;
						}
					}
				}

				.dropdown-header {
					padding: $dropdown-header-padding;
					font-family: $dropdown-header-font;
					font-size: $dropdown-header-font-size;
					letter-spacing: 2px;
					text-transform: uppercase;
					color: $dropdown-header-color;
				}

				.divider {
					margin-left: 25px;
					margin-right: 25px;
					background-color: $dropdown-divider-color;
				}
			} // .dropdown-menu

			.bg-solid {
				background: $bg-solid-white;
			}

			.search-dropdown {
				padding-top: 10px;

				li {
					padding: 0 15px;

					form {

						input[type="search"] {
							border-radius: 0;
							font-size: .9em;
							letter-spacing: 1.5px;

							&:focus {
								outline: none;
								box-shadow: none;
								border-color: $dark;
							}
						}
					}
				}
			} // .search-dropdown

			.cart-dropdown {
				width: 290px;
				background: #fff;

				.cart-sep-top {
					margin-bottom: 15px
				}

				.cart-sep-bot {
					margin-top: 0;
				}

				.cart-item {
					padding: 0 25px 15px 25px;

					a {
						padding: 0;
						border: none;
						background: none;
						text-transform: none;
						font-size: 14px;
						letter-spacing: .5px;
						line-height: inherit;

						&:hover {
							padding: 0;
							border: none;
							background: none;
						}
					}

					.p-thumb {
						float: left;
						margin-right: 15px;
					}

					p {
						line-height: 14px;
					}

					.cp-name {
						font-size: 14px;
						font-weight: 300;
						padding: 0;
						letter-spacing: 1px;

						&:hover {
							padding: 0;
						}
					}

					.cp-price {
						font-size: 12px;
						letter-spacing: 1px;
						color: $gray-light;
					}

					.cart-remove-btn {
						padding: 0;
						float: right;
						font-size: 16px;
						color: $gray;
						border: none;
						background: none;

						&:hover {
							color: $dark;
							border: none;
							background: none;
						}

						&:focus {
							text-decoration: none;
						}
					}
				} // .cart-item

				.item-totals {
					padding-left: 25px;
					padding-right: 25px;

					span {
						float: right;
						font-family: $alt-heading-font;
						font-weight: 400;
						color: $gray;
					}
				}

				.cart-btns {
					padding: 30px 25px 10px 25px;

					.btn {
						position: relative;
						display: inline-block;
						padding: $btn-padding;
						background: $dark;
						color: $light;
						font-family: $btn-font;
						text-transform: uppercase;
						letter-spacing: $btn-letter-spaceing;
						font-size: $btn-font-size;
						line-height: $btn-line-height;
						border-radius: $btn-rounded;
						transition: all $btn-trans-time;

						&:visited {
							color: $light;
							background: $dark;

							&:hover {
								color: $dark-blue;
								background: lighten($dark, 18%);
							}
						}

						&:hover {
							color: $light;
							background: lighten($dark, 18%);
							border-color: lighten($dark, 18%);
						}

						&:focus {
							color: $light;
							outline: none;
						}
					} // .btn

					.btn-ghost {
						@extend .btn;
						background: none;
						color: $dark;
						border: 2px solid $dark;
						text-align: center;
						overflow: hidden;
						z-index: 1;

						&:after {
							content: '';
							position: absolute;
							background: $dark;
							left: 0;
							top: 100%;
							width: 100%;
							height: 100%;
							transition: top .3s;
							z-index: -1;
						}

						&:visited,
						&:focus {
							color: $dark;
							text-decoration: none;
						}

						&:hover {
							color: $light;
							background: none;

							&:visited {
								color: $light;
								text-decoration: none;
							}

							&:after {
								top: 0;
							}
						}
					}

					.btn-light {
						@extend .btn;

						color: $dark;
						background: $light;

						&:visited {
							color: $dark;
							background: $light;

							&:hover {
								color: $dark;
								background: darken($light, 18%);
							}
						}

						&:hover {
							color: $dark;
							background: darken($light, 18%);
							border-color: darken($light, 18%);
						}

						&:focus {
							color: $dark;
						}
					}

					.btn-ghost-light {
						@extend .btn-ghost;
						z-index: 1;
						border-color: $light;
						color: $light;

						&:after {
							background: $light;
						}

						&:hover {
							color: $dark;
							border-color: $light;

							&:visited {
								color: $dark;
							}
						}

						&:visited {
							color: $light;
						}
					}
				}
			}

			.dropdown-banner {
				margin-left: -13px;
			}
		} // .dropdown
	} // .navbar-nav
} // .navbar



/* ---- Navbar Inverse (Dark) ---- */
.navbar-inverse {
	border-bottom: 1px solid $navbar-inverse-border-color;
	background: $navbar-inverse-background;

	.navbar-nav {
		
		li {

			a {
				color: $nav-inverse-links-color;

				&:hover {
					color: $nav-inverse-links-color-hover;
				}
			}
		}

		.active {

			a {
				color: $nav-inverse-link-active-color;
			}
		}

		.dropdown {

			.dropdown-menu {
				background: $dropdown-inverse-background;
				border-color: $navbar-inverse-border-color;

				li {

					a {

						&:hover {
							background: $dropdown-inverse-link-background;
							border-left: $dropdown-inverse-link-left-border;
						}
					}
				}

				.dropdown-header {
					color: $dropdown-inverse-header-color;
				}

				.divider {
					background-color: $dropdown-inverse-divider-color;
				}

				.bg-solid {
					background: $bg-solid-black;
				}
			} // .dropdown-menu

			.cart-dropdown {

				.cart-item {

					.cart-remove-btn {
						
						&:hover {
							color: $light;
						}
					}
				}

				.item-totals {
					color: $light;
				}				 
			}

			.search-dropdown {

				li {

					form {

						input[type="search"] {

							&:focus {
								border-color: $nav-inverse-searh-border-color;
							}
						}
					}
				}
			} // .search-dropdown
		} // .dropdown
	} // .navbar-nav	
} // .navbar-inverse



/* ---- Mega Menu ---- */
.mega {

  .nav, 
  .collapse, 
  .dropup, 
  .dropdown {
    position: static;
  }  

  .container {
    position: relative;
  }

  .dropdown-menu {
    left: auto;
  }
  
  .mega-content {
    padding: 20px 30px;
  }

  .dropdown.mega-fw .dropdown-menu {
    left: 0; right: 0;
  }

}



/* ---- Navbar Utility ---- */
.navbar-trans {
	border-bottom: none;
	background: rgba(0,0,0, 0);
	transition: background .3s;

	.navbar-logo {
		content: url(../images/logo-light.png);
	}

	.navbar-logo-dark {
		content: url(../images/logo.png);
	}
}

.navbar-trans:not(.navbar-inverse) {

	.navbar-nav {

		li {

			a {

				&:focus,
				&:hover {
					color: $light;
				}
			}
		}

		.active {

			a {
				color: $light;

				&:hover {
					color: $light;
				}
			}
		}

		.dropdown {

			.dropdown-menu {

				li {

					a {

						&:hover {
							color: $dark;
						}
					}
				}
			}
		}

		.open {

			.dropdown-toggle {
				color: $light;
			}
		}
	} // .navbvar-nav
} // .navbar-trans:not

.navbar-trans-dark {

	.navbar-nav {

		> li {

			> a {
				color: $white;
				&:focus,
				&:hover {
					color: $white;
				}
			}
		}

		.active {

			> a {
				color: $light-blue;

				&:hover {
					color: $light-blue;
				}
			}
		}

		.open {

			.dropdown-toggle {
				color: #fff;
			}
		}
	}
}

.navbar-small {
	min-height: 50px;

	.navbar-header {
		margin-top: 0;
		padding: 0;

		.navbar-brand {
			padding: 9px 0;
		}
	}

	.navbar-nav {
		margin-top: 0;

		.dropdown {

			.dropdown-menu {
				margin-top: 0;
			}
		}
	}
}

.navbar-fw {

	.navbar-header {
		margin-left: $ws-s;
		width: 25%;
	}

	.navbar-right {
		margin-right: $ws-s;
	}
}


/* ---- Navbar Utility ---- */
.bg-solid-white {
	background: #fff;
}

.bg-solid-dark {
	background: #111;
}