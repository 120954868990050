/* --------------------------------------------------
	13.0 Clients Layout
-------------------------------------------------- */



.clients-layout {

	.client-item {
		background: #fff;
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
		transition: all .3s;

		&:hover {
			transform: translateY(-10px);
			box-shadow: 0 22px 43px rgba(0, 0, 0, 0.15);
		}

		.item-content {
			padding: 50px;
		}

		h2,
		h3,
		h4,
		h5,
		h6 {
			margin: 0 0 27px 0;
		}

		p {
			margin: 0;
		}

		.cli-btn {
			margin-top: 30px;
		}
	}
}