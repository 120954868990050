@import "settings";

/*** CUSTOM CSS ****/

.white-text {
  color: $white;
}

#page_content img {
  padding: .6em;
  border: 1px solid #ccc;
}
#page_content img.no-frame {
  padding: 0;
  border: none;
}


/*** RESPONSIVE VIDEO ****/
.videoWrapper {

	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
  height: 0;
  @include respond-above(lg) {
    margin-top: 10em;
  }
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
  height: 100%;
}

/*************************/


/*** HERO FRONT PAGE ***/

.main-demo-hero {
  position: relative;
  width: 100%;
  min-height: 850px;
  height: auto;
  background-image: url(/theme/media/hero/hero-480x700.jpg);
  background-size: cover;
  background-attachment: scroll;
  background-position: center;
    @media(min-width:480px) {
      background-image: url(/theme/media/hero/hero-780x1200.jpg);
      min-height: 950px;
    }
    @media(min-width:780px) {
      background-image: url(/theme/media/hero/hero-1280x1578.jpg);
      min-height: 950px;
    }
    @media(min-width:1280px) {
      background-image: url(/theme/media/hero/hero-2440x1578.jpg);
      min-height: auto;
      height: 100vh;
    }

  .bg-overlay {
    position: absolute;
    width: 100%;
    min-height: 850px;
    height: auto;
    top: 0;
    left: 0;
    background: rgba(0,0,0, .6);
    @media(min-width:480px) {
      min-height: 950px;
    }
    @media(min-width:780px) {
      min-height: 950px;
    }
    @media(min-width:1280px) {
      min-height: auto;
      height: 100vh;
    }


  }

  .hero-content-wrapper {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding-left: 10%;

    .hero-content {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;

      .hero-lead {
        font-size: 3em;
        font-weight: 700;
        letter-spacing: 2px;
        margin-top: 50px;
        text-align: center;
        @media(min-width: 779px){
            font-size: 6em;
          text-align: left;
        }
      }

      .hero-subheading {
        margin-top: $ws-s;
        margin-bottom: 80px;
        color: $light;
      }
    }
  }
}


.agency2-hero .hero-content-wrapper, .animated-hero .hero-content-wrapper, .freelancer-hero .hero-content-wrapper, .fs-video-hero .hero-content-wrapper, .fw-video-hero .hero-content-wrapper, .main-demo-hero .hero-content-wrapper, .text-slider-hero .hero-content-wrapper, .web-app-hero .hero-content-wrapper {
  margin-top: 100px;
  padding: 0;

  @media (min-width:600px) {
    margin-top: 100px;
  }
  @media (min-width:990px) {
    padding-left: 10%;
    margin-top: 100px;
  }

}

/*** Button Color ***/

.btn-ghost, .btn-ghost-light, .navbar .navbar-nav .dropdown .cart-dropdown .cart-btns .btn-ghost-light {
  color: #000;
}

/*** MAP CSS ***/

.map-boxed {
  height: 580px;
}

/*** Contact Pane Bloc ***/
.cta-link {
  background: url(../../assets/images/music_bak.jpg) repeat;
}
.cta-link .bg-overlay {
  width: 100%;
  background: hsla(0,0%,7%,.9);
}

/*** NAV LINKS ***/


.navbar-inverse .navbar-nav > li > a:hover {
  color: $dark-blue;
}

.navbar-trans-dark .navbar-nav > li > a:focus, .navbar-trans-dark .navbar-nav > li > a:hover {
  color: $light-blue;
}
.navbar-header a.navbar-brand {
  display: none;
  @media(min-width:520px) {
    display: block;
  }
}
.navbar-small .navbar-header .navbar-brand {
  padding: 5px 0;
}
.navbar-trans .nav.navbar-nav {
  margin-top: 1em;
  margin-left: 9em;
  transition: all .5s;
}
.nav.navbar-nav {
  margin-top: 0;
  margin-left: 9em;
}
.navbar-trans .nav.navbar-right {
  margin-top: 1em;
  margin-left: 4em;
}
.nav.navbar-right {
  margin-top: 0;
  margin-left: 4em;
}
.mobile-nav .nav.navbar-nav {
  margin-left: 0;
}

.navbar-inverse .navbar-nav .dropdown .dropdown-menu li a:hover {
  color: $dark-blue;
}
/*** NAV PILLS ***/

ul#teacher_tabs.nav-pills li {
  float: left;
  margin-bottom: 1em;
}

/*** TEACHER LISTING PAGE ***/

.class-teachers img {
  float: left;
  margin-right: 2em;
}
.class-teachers .teacher-profile {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 1em 2em;
}

/*** CONTACT PAGE ***/

#contact_content {
  margin: 0;
  padding: 0;
}

/*** logo ***/

.svg-logo {
  path.outercolor {
      fill:   #fff;
    }
  path.innercolor {
    fill: rgba(0, 76, 132,.4);
  }
  max-width: 70%;
  height: auto;
  margin: 0 auto;

  @media(min-width:700px) {
    max-width: 40%;
  }

}
.test-logos {
  width: 50%;
  margin: 0 auto;
  path.outercolor {
    fill: #c8c8c8;
  }
  path.innercolor {
    fill: #898989;
  }
}


/*** TESTIMONIALS ***/

#testimonials .collapse {
  display: block;
  max-height: 11em;
  overflow: hidden;
  height: auto !important;
}
#testimonials .collapse.in {
  display: block;
  height: auto;
  max-height: inherit;
}
#testimonials .panel {
  border: none !important;
  margin: 0 0 1.3em 0;
  padding: 0;
  box-shadow: none;
}

/*** registration form ***/
.registration-form {
  input.start-date, input.end-date {
    width: 200px;
    display: block;
      @media(min-width: 650px) {
      display: inline-block;
    }
  }
  h2,h3 {
    margin-bottom: 1.5em;
    margin-top: 0;
  }

  .form-control {
    border: 2px solid #d1d1d1;
    border-radius: 5px;
    margin-bottom: 1em;
  }
  .form-group {
    margin-bottom: 2em;
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    padding: 2em;

    &.shaded {
      background-color: #F5F5F5;
    }

    .form-group {
      @media(min-width:1120px){
        margin: 2em 0 0 90px;
      }
    }
  }

}


/*** CHECKOUT CSS ***/

#simplecartCheckout {
  position: relative;
  width: inherit;
}

#simplecartCheckout div {
  position: relative;
}

#simplecartCheckout div:after {
  height: 0px;
  line-height: 0px;
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
}

//#simplecartCheckout div label {
//  position: relative;
//  display:  block;
//}

#simplecartCheckout div label span {
  color: #ff0000;
}

#simplecartCheckout div input[type='text'] {
  position: relative;
  margin-bottom: 3px;
}

#simplecartCheckout div label.error {
  position: relative;
  width: inherit;
  display: inline-block;
  color: #ff0000;
}

/*** FOOTER ***/
footer {
  .section {
    padding: 4em 0;
  }
  h4 {
    color: #ececec;
    line-height: 2em;
  }
  &.footer-widgets .about-widget .social-links li a {
    color: #337ab7;
    transition: .5s all;
  }
  &.footer-widgets .about-widget .social-links li a:hover {
    color: #fff;
  }
  .footer-logo {
    width: 50%;
    margin: 0 auto;
    path.outercolor {
      fill: #ececec;
    }
    path.innercolor {
      fill: $dark-blue;
    }
  }
  .widget.evangelos {
    text-align: left;
    @media(min-width: 780px){
      text-align: right;
    }
  }
}


/*** CUSTOM CSS ****/
.nav-pills li.active > a {
  color: $light !important;
}


.classes_block .ft-content h5,
.classes_block .ft-material h5{
  margin-top: 1em;
}

/*** YOUTUBE VIDEO ***/

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-descrip h3 {
  text-align: center;
  margin-bottom: $ws-s;
  line-height: 1.4em;
  @media(min-width:990px) {
      margin-top: 4em;
  }
}

.video_block {
  position: relative;
  background: #ccc url(/theme/media/parallax/video-2440x1578.jpg);
  background-attachment: scroll;
  background-size: auto auto;
  background-size: cover;
  background-attachment: fixed;
  box-shadow: 0px 0px 13px rgba(0,0,0,.6);
}

/*** input buttons ***/

/* ---- 2.1 Defualt Buttons ---- */
input.btn {
  position: relative;
  display: inline-block;
  padding: $btn-padding;
  background: $light-blue;
  color: $dark;
  font-family: $btn-font;
  text-transform: uppercase;
  letter-spacing: $btn-letter-spaceing;
  font-size: $btn-font-size;
  line-height: $btn-line-height;
  border-radius: $btn-rounded;
  transition: all $btn-trans-time;

  &:hover {
    color: $light;
    background: lighten($light-blue, 18%);
  }

  &:focus {
    color: $light;
    outline: none;
  }
} // btn;

input.btn-ghost {
  @extend input.btn;
  background: none;
  color: #000;
  border: 2px solid $light;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    background: $light-blue;
    left: 0;
    top: 100%;
    width: 100%;
    height: 100%;
    transition: top .3s;
    z-index: -1;
  }
  &:focus {
    color: $light-blue;
    text-decoration: none;
  }
  &:hover {
    color: $light;
    background: $light-blue;
    &:after {
      top: 0;
    }
  }
}

/* ---- 2.3 Buttons Rounded ---- */
input.btn-round {
  border-radius: 25px;
}
