/* --------------------------------------------------
	3.0 Forms
-------------------------------------------------- */



.form-control {
	height: $input-height;
	padding-left: 20px;
	border: 1px solid $input-border-color;
	border-radius: 0;
	box-shadow: none;
	line-height: 22px;
	letter-spacing: .5px;
	transition: border-color .5s;

	&:focus,
	&:hover {
		outline: none;
		box-shadow: none;
		border-color: $input-border-color-focused;
	}
}

input[type="file"] {
	background: #f9f9f9;

	&:focus,
	&:hover {
		outline: none;
		box-shadow: none;
		border-color: $input-border-color !important;
	}
}

.form-control[disabled], 
fieldset[disabled] .form-control {
	cursor: not-allowed;
	border-color: $input-border-color;

	&:focus,
	&:hover {
		border-color: $input-border-color !important;
	}
}

.form-group {

	label {
		font-size: .8em;
		font-family: $heading-font;
		text-transform: uppercase;
		letter-spacing: 2px;
		color: $dark;
	}
} // .form-group