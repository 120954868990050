/* 

---------- TABLE OF CONTENTS ----------
	
	0.1 Bootstrap styles
	0.2	Fonts imports
	0.3 Variables
	0.4 Utilities

	1.0 Typography

		1.1	Text
		1.2	Headings
		1.3 Links
		1.4 Blockquotes
		1.5 Lists
		1.6 PAGE - Typography
	
	2.0 Buttons

		2.1 Default Buttons
		2.2 Ghost Buttons
		2.3 Buttons Rounded
		2.4 Text Buttons
		2.5 Buttons Sizes
		2.6 Light Buttons
		2.7 PAGE - Buttons

	3.0 Forms

	4.0 Headers/Page Titles

		4.1 Breadcrumbs
		4.2 Header/Page Title
		4.3 Header/Page Title Styles
		4.4 Header/Page Title Sizes
		4.5 Header/Page Title Parallax

	5.0 Alerts
	6.0 FAQ - Accordions

	7.0 Footers

		7.1 Footer Widgets
		7.2 Footer Contact
		7.3 Footer Litle

	8.0 Navigation

	9.0 Features

		9.1 Feature - Cards
		9.2 Feature - Cards 2
		9.3 Feature - Steps Numbers
		9.4 Feature - Centered
		9.5 Feature - Horizontal Aligned
		9.6 Feature - Vertical Aligned
		9.7 Feature - Boxed Hover List
		9.8 Feature - Tabs
		9.9 Lists
		9.10 Feature Layout 50/50
		9.11 Feature Hover Box
		9.12 Video Background
		9.13 Feature - Big Images + Text
		9.14 Feature - Image Left
		9.15 Tabs
		9.16 Utiliy Classes

	10.0 Counters

		10.1 Circle Counter
		10.2 Counters - Numbers

	11.0 Sliders

	12.0 Testimonials
		
		12.1 Testimonials - 1 Column
		12.2 Testimonials - 3 Columns Cards
		12.3 Clients List

	13.0 Clients
		
	14.0 Team Blocks

		14.1 Team - 3 Columns
		14.2 Team - 4 Columns
		14.3 Freelancer

	15.0 Portfolio

		15.1 Full Width - Columns
		15.2 Boxed Columns
		15.3 Masonry Layout
		15.4 Single Column
		15.5 Portfoli Single - Project Page

	16.0 Pricing Tables

	17.0 CTA - Call to Action

		17.1 CTA - Link
		17.2 CTA - Newsletter Signup

	18.0 Blog

		18.1 Blog Columns
		18.2 Blog Masonry
		18.3 Blog Classic
		18.4 Blog Post Single
		18.5 Sidebar
		18.6 Comments
	
	19.0 Contact

		19.1 Contact Layout 1
		19.2 Contact Layout 2
		19.3 Contact Layout 3

	20.0 Hero
		
		20.01 Preloader
		20.02 Scroller
		20.03 Hero Sliders Navigation
		20.1 Main Onepage
		20.2 Full Screen Slider
		20.3 Full Width Slider
		20.4 Full Screen Video
		20.5 Full Width Video
		20.6 Text Slider
		20.7 Freelancer
		20.8 Agency
		20.9 Animated Heading
		20.10 Kenburn Slider
		20.11 Landing Page

	21.0 Login Pages

		21.1 Login Page 1
		21.2 Login Page 2

	22.0 Coming Soon

		22.1 Coming Soon - Page 1
		22.2 Coming Soon - Page 2

	23.0 Maintenance Page

	24.0 404 Page

	25.0 Labels

	26.0 Hover Effects
		
		26.1 Hover Default - Effect
		26.2 Hover Bottom - Effect
		26.3 Hover Side Panel - Effect

	27.0 Shop (E-Commerce) Layout

		27.1 Shop Layout
		27.2 Shop Product Card
		27.3 Shop Sidebar - (horizontal)
		27.4 Shop Sidebar - (vertical)
		27.5 Checkout Page
		27.6 Shop Product Single

	28.0 Tables


---------- END - TABLE OF CONTENTS ----------
*/



